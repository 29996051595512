import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import imageButtonLogin from "./login-button.png";
import imageButtonPromotion from "./promotion-button.png";
import imageButtonRegister from "./register-button.png";
import imageHead from "./head.jpg";
import { within } from "@testing-library/react";
import imageHeadgif from "./ufa-web.gif";
import imageGamegroupf from "./game-group.png";
import image3step from "./3-step.jpg";
import imageRegister from "./register---ปุ่มลอย.png";

import menutop from "./menu-top.png";
import Logogif from "./ufa-web.gif";
import Logopng from "./ufa-logo.png";

import imageJackport from "./jackport.gif";
import imageStep3 from "./3-step.jpg";
import imageCasino from "./casino-icon.ebcea54.png";

import imageDetailcasino_box1 from "./deam-gaming.png";
import imageDetailcasino_box2 from "./live-casino.png";
import imageDetailcasino_box3 from "./sagame.png";
import imageDetailcasino_box4 from "./sexygame.png";
import imageSport from "./icon-huay.51854b1.png";
import imageMbappe from "./mbappe.png";
import imageSLOT from "./SLOT-icon.38cb61c.png";

import boxgame1 from "./slot/amazing-soda-rush.webp";
import boxgame2 from "./slot/big-cat-king-megaways.webp";
import boxgame3 from "./slot/buffalo-hunter.webp";
import boxgame4 from "./slot/diamond-mine-extra-gold-all-action.webp";
import boxgame5 from "./slot/egypt-bonanza.webp";
import boxgame6 from "./slot/fizzy-pennyslot.webp";
import boxgame7 from "./slot/gong-hei-gong-hei.webp";
import boxgame8 from "./slot/imperial-dragon.webp";
import boxgame9 from "./slot/journey-to-the-abyss.webp";
import boxgame10 from "./slot/last-valkyrie.webp";
import boxgame11 from "./slot/mask-carnival.webp";
import boxgame12 from "./slot/mega-cave.webp";
import boxgame13 from "./slot/ninja-raccoon-frenzy.webp";
import boxgame14 from "./slot/ninja-vs-samurai.webp";
import boxgame15 from "./slot/panda-dragon-boat.webp";
import boxgame16 from "./slot/piggy-gold.webp";
import boxgame17 from "./slot/treasure-raiders.webp";
import boxgame18 from "./slot/wild-fireworks.webp";
import imageQuarantee from "./guarantee.jpg";
import imageslotgame from "./slotgame-icon.a4a44de.png";
import imageGameroup from "./game-group.png";

function App() {
  const [number, setNumber] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupPromotion, setShowPopupPromotion] = useState(false);
  const [winner, setWinner] = useState("");
  // LINE ID ของคุณ (ใช้ @ ไอดีบัญชีของคุณ)
  const lineId = "4KyNzcy";

  // สร้าง URL ของ LINE เพื่อนำไปใช้
  // const lineUrl = `https://line.me/R/ti/p/${lineId}`;
  const lineUrl = `https://lin.ee/4KyNzcy`;
  const Urllink = `https://ag.ufa7777.com/Public/Default11.aspx?lang=EN-GB`;

  const nf2 = new Intl.NumberFormat("en-thai", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  // ฟังก์ชันสุ่มชื่อ
  function generateRandomName(length, language) {
    // ชุดตัวอักษรสำหรับภาษาอังกฤษ
    const englishCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    // ชุดตัวอักษรที่ใช้บ่อยในชื่อภาษาไทย
    const thaiConsonants = "กขคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรฤลฦวศษสหฬอฮ";
    const thaiVowels = "ะาิีืุูเแโใไ";

    // ชุดพยางค์สำหรับชื่อผู้ชายและผู้หญิง
    const thaiMaleNames = [
      "รัตน์",
      "ธนวัฒน์",
      "กิตติ",
      "อำนวย",
      "ปกรณ์",
      "สุทธิ",
      "วิรัตน์",
      "สมชาย",
      "ธวัชชัย",
      "สิรินทร์",
      "ภัทร",
      "ธนธร",
      "จิตร",
      "ธีรชัย",
      "ศุภชัย",
      "อานนท์",
      "อัศวิน",
      "มงคล",
      "ชาญ",
      "ภูมิ",
      "อรรถพล",
      "ปิยะ",
      "พงศ์เทพ",
      "อรรถกฤษณ์",
      "จักรพงษ์",
      "ชัย",
      "พงษ์",
      "อภิชาติ",
      "นิรันดร์",
      "ชลทิศ",
      "ศักดิ์",
      "ลิขิต",
      "วิทยา",
      "สุรศักดิ์",
      "อรรถพล",
      "ชัยณรงค์",
      "สิทธิพงษ์",
      "สุรศักดิ์",
      "พัฒน์",
    ];

    const thaiFemaleNames = [
      "พร",
      "อารีย์",
      "สุภาพ",
      "นภา",
      "จิรา",
      "ณัฐธิดา",
      "มยุรา",
      "นิตยา",
      "ปัทมาภรณ์",
      "พิมพ์ใจ",
      "กาญจนา",
      "ธิดา",
      "จิราภรณ์",
      "ลัดดา",
      "พรรณี",
      "ปุณณภา",
      "ประณีต",
      "สุพรรณ",
      "ดวง",
      "ศิริ",
      "จุฑามาศ",
      "ทิพย์",
      "ภัทร",
      "รัชนี",
      "รุ่งโรจน์",
      "นลิน",
      "วิภา",
      "ณัฐ",
      "มณฑนา",
      "ศศิธร",
      "เพชร",
      "อรทัย",
      "อภิญญา",
      "ศิริวรรณ",
      "สุธิดา",
      "ทิพย์สุดา",
      "ดวงดาว",
      "วัชราภรณ์",
      "ชญานิษฐ์",
      "รัตนา",
      "นันทนา",
      "ภาวดี",
      "อร",
      "ทวี",
      "กุลยา",
      "นรีรัตน์",
    ];

    // เลือกชุดตัวอักษรตามภาษาที่กำหนด
    let characters;
    if (language === "thai") {
      characters = thaiConsonants + thaiVowels;
    } else {
      characters = englishCharacters;
    }

    let randomName = "";
    if (language === "thai") {
      // สุ่มเลือกชุดพยางค์
      const isMale = Math.random() < 0.75; // 75% chance for male names
      const nameList = isMale ? thaiMaleNames : thaiFemaleNames;

      // สุ่มชื่อจากชุดพยางค์ที่เลือก
      randomName = nameList[Math.floor(Math.random() * nameList.length)];
    } else {
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomName += characters[randomIndex];
      }
    }

    return randomName;
  }

  // ฟังก์ชันปิด popup
  function closePopup() {
    setShowPopup(false);
  }

  // ฟังก์ชันจัดการการคลิกที่ popup
  function handlePopupClick() {
    closePopup();
  }
  // ตั้งค่าการปิด popup อัตโนมัติหลังจาก 5 วินาที
  useEffect(() => {
    let timer;
    if (showPopup) {
      timer = setTimeout(() => {
        setShowPopup(false);
      }, 5000); // ปิด popup หลังจาก 5 วินาที
    }
    return () => clearTimeout(timer); // ทำความสะอาดเมื่อ component ถูก unmount
  }, [showPopup]);

  useEffect(() => {
    // ตั้งค่าเริ่มต้นของตัวเลขให้เป็นหนึ่งแสนกว่าแบบสุ่ม
    const initialNumber = 100000 + Math.random() * 1000; // เพิ่มทศนิยมด้วย Math.random()
    setNumber(initialNumber);

    function updateNumber() {
      setNumber((prevNumber) => {
        // ตรวจสอบว่าตัวเลขถึงช่วง 3 ถึง 120 ล้านหรือไม่
        if (prevNumber >= 3000000 && prevNumber <= 120000000) {
          // แสดง popup และสุ่มชื่อ
          setShowPopup(true);
          // สุ่มภาษา
          const language = Math.random() > 0.5 ? "thai" : "english";
          setWinner(generateRandomName(5, language)); // ตั้งค่าชื่อผู้ชนะ

          // รีเซ็ตตัวเลขกลับไปที่สุ่มระหว่าง 1 ถึง 100,000
          return Math.random() * 100000 + 1; // สร้างเลขใหม่ที่มีทศนิยม
        }
        return prevNumber + Math.random() * 100000 + 1; // เพิ่มค่าที่มีทศนิยม
      });

      // กำหนดช่วงเวลาสุ่มระหว่าง 1 ถึง 20 วินาที
      const randomDelay = Math.floor(Math.random() * 20000) + 1000;

      setTimeout(updateNumber, randomDelay);
    }

    // เรียกใช้ครั้งแรกหลังจากตั้งค่าเริ่มต้น
    updateNumber();

    // ทำความสะอาดเมื่อ component ถูก unmount
    return () => clearTimeout(updateNumber);
  }, []);

  const openRegister = (e) => {
    e.preventDefault();
    window.open(lineUrl, "_blank", "noopener,noreferrer");
  };

  const openLineUrl = (e) => {
    e.preventDefault();
    window.open(lineUrl, "_blank", "noopener,noreferrer");
  };

  const openUrllink = (e) => {
    e.preventDefault();
    window.open(Urllink, "_blank", "noopener,noreferrer");
  };

  const openLogin = (e) => {
    e.preventDefault();
    window.open(Urllink, "_blank", "noopener,noreferrer");
  };

  const openPromotion = (e) => {
    setShowPopupPromotion(true);
  };

  // ฟังก์ชันปิด popup
  function closePopupPromotion() {
    setShowPopupPromotion(false);
  }

  // ฟังก์ชันจัดการการคลิกที่ popup
  function handlePopupPromotionClick() {
    closePopupPromotion();
  }

  return (
    <div className="App">
      {/* {showPopup && (
        <div className="popup" onClick={handlePopupClick}>
          <div className="popup-content">
            <h2>JACKPOT</h2>
            <p> {winner}</p>
          </div>
        </div>
      )} */}
      <main>
        <div id="main">
          <div class="bodyRegister">
            <img src={imageRegister} onClick={openRegister}></img>
          </div>

          {/* <div class="do-container"> */}
          <div class="do-container-query">
            {showPopupPromotion && (
              <div className="popupPromotion">
                {/* <div className="popupPromotion-content"> */}
                <div className="bodypopupPromotion">
                  <div className="bodypopupPromotion_background1">
                    <a
                      href="#"
                      class="animated-button1"
                      onClick={handlePopupPromotionClick}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Close
                    </a>
                  </div>
                  <div className="bodypopupPromotion_background2">
                    <a
                      href="#"
                      class="animated-button1"
                      onClick={handlePopupPromotionClick}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Close
                    </a>
                  </div>
                  <div className="bodypopupPromotion_background3">
                    <a
                      href="#"
                      class="animated-button1"
                      onClick={handlePopupPromotionClick}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Close
                    </a>
                  </div>
                </div>
                {/* </div> */}
              </div>
            )}

            <section class="card-wrap">
              <img class="card-img" src={menutop}></img>
              <section class="card-wrap-Logopng">
                <img class="card-img-Logopng" src={Logopng}></img>
              </section>

              <div class="do-container-button">
                <div class="card_button">
                  <img src={imageButtonPromotion} onClick={openPromotion}></img>
                  <img src={imageButtonLogin} onClick={openLogin}></img>
                  <img src={imageButtonRegister} onClick={openRegister}></img>
                </div>
              </div>
            </section>
            <section class="card-wrap-logo">
              <img
                class="card-img-logo"
                src={Logogif}
                onClick={openUrllink}
              ></img>
            </section>
            <div class="do-container">
              <div className="bodyJackport">
                <div className="cardJackport">
                  <img src={imageJackport}></img>
                </div>
                <div className="bodyRunNumber">
                  <p>{nf2.format(number)}</p>
                </div>
              </div>

              <div className="bodystep3">
                <div className="cardstep3">
                  <img src={imageStep3} onClick={openUrllink}></img>
                </div>
              </div>

              <div className="bodycasino">
                <div className="cardcasino">
                  <img src={imageCasino} onClick={openUrllink}></img>
                </div>
              </div>

              <div className="bodyDetailcasino">
                <div className="cardDetailcasino">
                  <img src={imageDetailcasino_box1} onClick={openUrllink}></img>
                  <img src={imageDetailcasino_box2} onClick={openUrllink}></img>
                  <img src={imageDetailcasino_box3} onClick={openUrllink}></img>
                  <img src={imageDetailcasino_box4} onClick={openUrllink}></img>
                </div>
              </div>
              <div className="bodysports">
                <div className="cardsports">
                  <img src={imageSport} onClick={openUrllink}></img>
                </div>
              </div>

              <div className="bodyDetailsports">
                <div className="cardDetailsports">
                  <div className="bodyDetailsportsbox1">
                    <img src={imageMbappe}></img>
                  </div>
                  <div className="bodyDetailsportsbox2">
                    <p>
                      ufax898 เปิดเว็บพนันบอล เดิมพันกีฬาโดยตรงได้จาก OBET,
                      AFBSPORT ครบทุกลีกไม่ว่าจะลีกใหญ่ ลีกเล็ก
                      ซึ่งท่านสามารถเดิมพันบอลสเต็ป2-12คู่
                      เรตค่าน้ำดีที่สุดในไทย เว็บพนันบอล 2024 เป็นกีฬาระดับโลก
                      ที่ผู้คนต่างให้ความ สนใจกันค่อนข้างมาก
                      เดิมพันบอลไปกับทีมที่คุณชอบ อกจากท่านจะสามารถ แทงบอลผ่าน
                      ufax898 ได้แล้ว ยังมีเกมกีฬาอื่นๆอีกเพียบ เช่น บาสเก็ตบอล,
                      ไก่ชน, กีฬาเสมือนจริง, คาสิโนเกมส์สด, คีโน, การแข่ง,
                      เทนนิส,เบสบอล, มวย สากล, ฟุตซอล, ฮอกกี้, เกมส์ปาเป้า,
                      จักรยาน, วอลเล่ย์บอล, รักบี้, เทเบิลเทนนิส, เป็นต้น
                      ที่ครอบคลุมแมตท์ทั้งหมดมากกว่า 3,000 รายการ
                      เรียกได้ว่าสมัครที่นี้มี
                      ครบสามารถเลือกเล่นเพียงท่านเป้นสมาชิกกับเรา
                    </p>
                  </div>
                </div>
              </div>

              <div className="bodygame">
                <div className="cardgame">
                  <img src={imageSLOT} onClick={openUrllink}></img>
                </div>
              </div>

              <div className="bodygameDetail">
                <div className="cardgameDetail">
                  <img src={boxgame1}></img>
                  <img src={boxgame2}></img>
                  <img src={boxgame3}></img>
                  <img src={boxgame4}></img>
                  <img src={boxgame5}></img>
                  <img src={boxgame6}></img>
                  <img src={boxgame7}></img>
                  <img src={boxgame8}></img>
                  <img src={boxgame9}></img>
                  <img src={boxgame10}></img>
                  <img src={boxgame11}></img>
                  <img src={boxgame12}></img>
                  <img src={boxgame13}></img>
                  <img src={boxgame14}></img>
                  <img src={boxgame15}></img>
                  <img src={boxgame16}></img>
                  <img src={boxgame17}></img>
                  <img src={boxgame18}></img>
                </div>
              </div>

              <div className="bodyguarantee">
                <div className="cardguarantee">
                  <img src={imageQuarantee} onClick={openUrllink}></img>
                </div>
              </div>

              <div className="bodygame">
                <div className="cardgame">
                  <img src={imageslotgame} onClick={openUrllink}></img>
                </div>
              </div>

              <div className="bodyTestgame">
                <div className="cardTestgame">
                  <img src={boxgame1} onClick={openUrllink}></img>
                  <img src={boxgame2} onClick={openUrllink}></img>
                  <img src={boxgame3} onClick={openUrllink}></img>
                  <img src={boxgame4} onClick={openUrllink}></img>
                  <img src={boxgame5} onClick={openUrllink}></img>
                  <img src={boxgame6} onClick={openUrllink}></img>
                  <img src={boxgame7} onClick={openUrllink}></img>
                  <img src={boxgame8} onClick={openUrllink}></img>
                  <img src={boxgame9} onClick={openUrllink}></img>
                  <img src={boxgame10} onClick={openUrllink}></img>
                  <img src={boxgame11} onClick={openUrllink}></img>
                  <img src={boxgame12} onClick={openUrllink}></img>
                </div>
              </div>
            </div>
          </div>
          <div className="bodyGamegroup">
            <div className="cardGamegroup">
              <img src={imageGameroup}></img>
            </div>
          </div>
          {/* </div> */}
        </div>
      </main>
    </div>
  );
}

export default App;
